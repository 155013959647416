import React from 'react';
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

import Layout from '../../components/layout';
import { newEvent, trackCustomEvent } from '../../utils/event';
import { getIn } from '../../utils/functional';
import { httpPromise } from '../../utils/http';
import { refreshSessionId } from '../../utils/session_id';
import { emailIsValid } from "../../utils/validate";

import { labelNameTag, inputTag } from '../../components/login/input';
import { colors } from '../../components/colors';
import * as styles from "../../components/login/login.module.css";
import * as linkStyles from '../../components/link.module.css';
import * as buttonStyles from "../../components/button.module.css";

import { RenderHead } from "../../components/head";
const title = 'Forgot Password';
export const Head = () => RenderHead({ title });


const originType = process.env.ORIGIN_TYPE;
const privateContent = originType === 'private';
const shiftsEmailOnlyContent = originType === 'shifts-email-only';
const memberContent = privateContent || shiftsEmailOnlyContent;
const servicePath = process.env.USER_API_URL;


const message = {
    success: 'If there’s an account associated with that email address, we emailed you a reset password link.',
    email: 'Please enter a valid email address',
    connectivity: 'No internet connectivity. Please connect and try again',
    server: function ()
    {
        return (
            <div>
              <span>Server error. Please try again later or contact us at</span>

              <OutboundLink
                className={ linkStyles.errorLink }
                href={ 'mailto:help@6pages.com' } >
                help@6pages.com
              </OutboundLink>

              <span>for assistance</span>
            </div>
        );
    }()
};


const messageColor = function (type)
{
    switch (type)
    {
        case 'error': return colors.red; 
        case 'info':  return colors.orange;
        default:      return colors.mediumDarkGrey;
    };
};

const messageElem = function (type, text)
{
    return (
        <div
          className={ styles.container }
          style={{
              margin: `30px 0 0`,
              minHeight: `48px`,
              textAlign: `center`,
              fontSize: `13px`,
              fontStyle: `italic`,
              letterSpacing: `0.13px`,
              color: messageColor(type)
          }} >
          { text }
        </div>
    );
};


//
// helpers


const request = function  (email)
{
    return 'mutation { '
        + 'password_reset_request( '
        + 'email: "' + email + '", '
        + ' ) '
        + ' }';
};


const backLink = function ()
{
    if (memberContent)
        return (
            <Link
              className={ linkStyles.greenLink }
              style={{ margin: `0 3px 0` }}
              to={`/account`} >
              Back to Account
            </Link>
        );
    
    return (
        <Link
          className={ linkStyles.greenLink }
          style={{ margin: `0 3px 0` }}
          to={`/login`} >
          Back to Log In
        </Link>
    );
};


//
// Form


class Form extends React.Component
{
    constructor (props)
    {
        super(props);
        this.state = {
            email: { value: '' },
            message: { type: '', text: '' },
            inFlight: false
        };

        this.setMessage = this.setMessage.bind(this);
        this.setInFlight = this.setInFlight.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    setMessage (type = '', text = '')
    {
        this.setState({ message : { type: type, text: text }});
        
    }

    setInFlight (v = false)
    {
        this.setState({ inFlight: v });
    }

    handleChange (event)
    {
        this.setState({ email: { value: event.target.value }});
        this.setMessage();
    }

    handleSubmit (event)
    {
        event.preventDefault();

        if ( this.state.inFlight ) return;    // request already in flight

        if ( ! emailIsValid(this.state.email.value) )
            this.setMessage( 'error', message.email );
        else
        {
            this.setInFlight(true);
            
            // make password reset request ...request
            const body = request(this.state.email.value);
            httpPromise({ url: servicePath, data: body })
                .then(function (result)
                      {
                          const response = result.response;
                          if (response)
                          {
                              const responseData = JSON.parse(response);
                              const r = getIn(responseData, ['data', 'password_reset_request']);
                              if ( r !== "null" )
                              {
                                  newEvent({
                                      'level':           'info',
                                      'event.namespace': 'web.password.reset',
                                      'event.name':      'success',
                                      'email':           this.state.email.value
                                  });

                                  trackCustomEvent({
                                      category: 'forgot-password',
                                      action: 'success'
                                  });

                                  this.setMessage( 'info', message.success );
                              }
                              else if (getIn(responseData, ['errors']))
                                  this.setMessage('error', getIn(responseData, ['errors', '0']));
                          }
                          else
                              this.setMessage('error', message.connectivity);
                      }.bind(this))
                .catch(function (result)
                       {
                           newEvent({
                               'level':                     'warn',
                               'event.namespace':           'web.password.forgot',
                               'event.name':                'error',
                               'data':                       result
                           });
                           this.setMessage('error', message.connectivity);
                       }.bind(this))
                .finally(function ()
                         {
                             this.setInFlight();
                         }.bind(this));
        }
    }
    
    render ()
    {
        return (
            <React.Fragment>
              <form
                onSubmit={this.handleSubmit} >

                <div
                  className={ styles.container }
                  style={{
                      display: `flex`,
                      flexDirection: `column`,
                      justifyContent: `flex-start`,
                      alignItems: `flex-start`
                  }} >

                  <label
                    style={{
                        width: `inherit`,
                        margin: `50px 0 0`,
                    }} >

                    { labelNameTag("Email address") }
                    { inputTag( "text", "EmailAddress", this.state.email.value, this.handleChange ) }

                  </label>

                </div>

                { messageElem(this.state.message.type, this.state.message.text) }

                <div
                  style={{
                      marginTop: `15px`,
                      width: `100%`,
                      display: `flex`,
                      flexDirection: `column`,
                      justifyContent: `center`,
                      alignItems: `center`
                  }} >
                  
                  <input
                    type='submit'
                    aria-label='Reset password'
                    value='Reset password'
                    disabled={ this.state.inFlight }
                    className={ buttonStyles.submit }
                    style={{
                        width: `203px`,
                        height: `48px`
                    }} />
                  
                </div>
                
              </form>
            </React.Fragment>
        );
    }
}



//
// Component


class Forgot extends React.Component
{
    componentDidMount () {
        refreshSessionId();
    }
    
    render()
    {
        return (
            <Layout
              location={ this.props.location }
              removeHorizontalPadding={ true } >                              

              <div
                style={{
                    margin: `70px auto 90px`,
                    display: `flex`,
                    flexDirection: `column`,
                    justifyContent: `flex-start`,
                    alignItems: `center`
                }} >

                <div
                  style={{
                      margin: `0 60px`,
                      fontFamily: `"Overpass", sans-serif`,
                      fontSize: `40px`,
                      fontWeight: `bold`,
                      lineHeight: `1.13`,
                      textAlign: `center`,
                      color: colors.green
                  }} >
                  Forgot Password?
                </div>

                <div
                  style={{
                      margin: `20px 0 0`,
                      fontSize: `14px`,
                      lineHeight: `1.79`,
                      letterSpacing: `0.14px`,
                      color: colors.black
                  }} >
                  We'll email you a link to reset it
                </div>

                <Form />
                
                <div
                  style={{
                      margin: `10px 0 40px`,
                      fontSize: `14px`,
                      lineHeight: `1.79`,
                      letterSpacing: `0.14px`,
                      color: colors.black
                  }} >

                  { backLink() }

                </div>
                
              </div>
            </Layout>
        );
    }
}


export default Forgot;
